import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';
import { isRegionalPage } from '$modules/shipping/utils/region-url';
import { regionMeta } from 'theme/meta/utils/region';
import i18n from '@vue-storefront/i18n'

export function getMetaContent (category, route) {
  const h1 = category?.meta_h1 || category?.name || i18n.t('Brands')
  const title = category?.meta_title || `${h1} | VARUS`
  const description = category?.meta_description || `${h1} ${i18n.t('META_OTHER_PAGES_DESCRIPTION')}`

  const regionalMeta = regionMeta(route?.fullPath, h1)

  return {
    h1: regionalMeta?.h1 || h1,
    title: regionalMeta?.title || title,
    description: regionalMeta?.description || description,
    region: regionalMeta?.region
  }
}

export function metaBrands () {
  const metaContent = getMetaContent(this.$route, this.getBrandsCategory)
  let relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);

  if (isRegionalPage(this.$route.fullPath) && !metaContent?.region?.is_indexed) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const meta = {
    title: metaContent?.title,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaContent?.description
      }
    }
  };

  return mergeMeta(meta)
}
