<template>
  <div id="brands" class="brands">
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div class="navbar section">
      <div class="navbar__main">
        <SfHeading
          :level="1"
          :title="pageTitle"
          class="navbar__title"
        />
        <div class="navbar__counter">
          <span v-if="brands.length" class="navbar__label">
            {{ brands.length }} {{ totalBrandsLabel() }}
          </span>
        </div>
      </div>
    </div>
    <div class="brands-content">
      <MBrandsTabsMob
        :active-category="activeCategory"
        :active-alphabet="activeAlphabet"
        :show-filter-alphabet="showFilterAlphavet"
        :show-filter-category="showFilterCategory"
        @triggerTabCategory="triggerTabCategory"
        @triggerTabAlphabet="triggerTabAlphabet"
      />
      <MBrandsFilterAlphabet
        :brand-groups="brandGroups"
        :brand-groups-alphabet="brandGroupsAlphabet"
        :key="filterBy"
        :active-alphabet="activeAlphabet"
        :show-filter-alphavet="showFilterAlphavet"
        @filterByAlphabet="filterByAlphabet"
      />
      <div class="brands-filter-category">
        <MBrandsFilterCategory
          :categories="categories"
          :show-filter-category="isDesktop || showFilterCategory"
          :active-category="activeCategory"
          @filterByCategory="filterByCategory"
        />
      </div>
      <div class="brands-content-lists">
        <MBrandsListBrand
          :sort-by-number="sortListBrandByNumber"
          :brand-groups="brandGroups"
          :key="filterBy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import MBrandsFilterAlphabet from 'theme/components/molecules/m-brands-filter-alphabet';
import MBrandsListBrand from 'theme/components/molecules/m-brands-list-brand';
import MBrandsFilterCategory from 'theme/components/molecules/m-brands-filter-category';
import MBrandsTabsMob from 'theme/components/molecules/m-brands-tabs-mob';
import { declension } from 'theme/helpers/text';
import { SfHeading } from '@storefront-ui/vue';
import { mapState } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import { getTopLevelCategories } from 'theme/helpers';
import { metaBrands } from 'theme/meta/brands';
import i18n from '@vue-storefront/i18n';
import deviceType from 'theme/mixins/DeviceType';
import { defineComponent } from 'vue';
import { useDevice } from 'src/composables/useDevice';
import { isSkipSSR } from 'theme/store/checkout/helpers';
const allCategoriesFilter = {
  name: i18n.t('All categories'),
  id: null
}

export default defineComponent({
  name: 'Brands',
  components: {
    MBreadcrumbs,
    JsonLdBreadcrumb,
    MBrandsFilterAlphabet,
    MBrandsTabsMob,
    MBrandsListBrand,
    MBrandsFilterCategory,
    SfHeading
  },
  mixins: [
    deviceType
  ],
  data () {
    return {
      showFilterCategory: false,
      sortListBrandByNumber: false,
      showFilterAlphavet: false,
      activeCategory: allCategoriesFilter,
      activeAlphabet: 'all',
      filterBy: '',
      arrayBrands: [],
      brands: [],
      brandGroups: {},
      brandGroupsAlphabet: {
        other: {
          brandsByLetter: {}
        },
        latin: {
          brandsByLetter: {}
        },
        cyrillic: {
          brandsByLetter: {}
        },
        numbers: {
          brandsByLetter: {}
        }
      }
    };
  },
  computed: {
    ...mapState({
      getBrandsCategory: (state) => state.brands.category,
      getBrands: (state) => state.brands.brands,
      getBrandGroups: (state) => state.brands.brandGroups,
      getBrandGroupsAlphabet: (state) => state.brands.brandGroupsAlphabet,
      getBrandsCategories: (state) => state.brands.categories
    }),
    pageTitle () {
      return this.getBrandsCategory?.meta_h1 || this.getBrandsCategory?.name || this.$t('Brands')
    },
    categories () {
      const { getBrandsCategories } = this
      return [allCategoriesFilter, ...getTopLevelCategories(getBrandsCategories)]
    }
  },
  asyncDataManual: true,
  async asyncData ({ store, context }) {
    if (context?.server?.request?.raw?.headers) {
      const { detectDeviceFromHeaders } = useDevice()
      const serverDeviceInfo = detectDeviceFromHeaders(context?.server?.request?.raw?.headers)

      await store.dispatch('ui/setDeviceInfo', serverDeviceInfo)
    }

    if (isSkipSSR()) return

    const brandCategory = await store.dispatch('brands/getBrandsCategory')
    await Promise.all([
      store.dispatch('brands/fetchBrandsCategoryList'),
      store.dispatch(
        'category-extension/loadCategoryBreadcrumbs',
        {
          category: brandCategory,
          currentRouteName: brandCategory.name,
          omitCurrent: true
        }
      ),
      store.dispatch('brands/fetchBrandsCollection'),
      store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('promoted/updatePreHeaderBanners')
    ]);
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next();

    next(async vm => {
      vm.loading = true;
      vm.initBrandData();
      vm.loading = false;
    });
  },
  methods: {
    initBrandData () {
      this.brands = this.getBrands
      this.brandGroups = this.sortObjectFromKey(this.getBrandGroups)
      this.arrayBrands = this.brandGroups
      this.brandGroupsAlphabet.cyrillic.brandsByLetter = this.sortObjectFromKey(this.getBrandGroupsAlphabet.cyrillic.brandsByLetter)
      this.brandGroupsAlphabet.latin.brandsByLetter = this.sortObjectFromKey(this.getBrandGroupsAlphabet.latin.brandsByLetter)
      this.brandGroupsAlphabet.numbers.brandsByLetter = this.getBrandGroupsAlphabet.numbers.brandsByLetter
    },
    sortObjectFromKey: function (object) {
      const sortedKeys = Object.keys(object).sort();
      const sortedObject = Object.fromEntries(
        sortedKeys.map(key => [key, object[key]])
      );
      return sortedObject;
    },
    totalBrandsLabel () {
      return declension([this.$t('brand'), this.$t('brands'), this.$t('brands')], this.brands.length)
    },
    filterByAlphabet (data) {
      this.filterBy = data
      if (data === 'all') {
        this.resetFilter()
        this.sortListBrandByNumber = false
      } else {
        this.sortListBrandByNumber = (data === '0-9')
        this.activeAlphabet = data.toUpperCase()
        this.brandGroups = this.activeCategory.id !== null
          ? Object.assign({ [data]: this.brandGroups[data] }, this.brandGroups)
          : Object.assign({ [data]: this.brandGroups[data] }, this.arrayBrands)
      }
      this.showFilterAlphavet = false
    },
    filterByCategory (category) {
      if (category.id !== null) {
        const arrayBrands = this.arrayBrands;
        this.filterBy = category.name
        if (category?.brand_data?.length > 0) {
          let obj = {}
          let keys = []
          let brandsName = category.brand_data.map((item) => {
            if (!keys.includes(item.name[0].toLowerCase())) {
              keys.push(item.name[0].toLowerCase())
            }
            return item.name
          })

          keys.forEach((key) => {
            let currentKey = key
            if (/[0-9]/.test(key)) {
              currentKey = '0-9'
            }
            if (arrayBrands[currentKey] === undefined) {
              return
            }
            let brandsObj = arrayBrands[currentKey].filter((item) => {
              return brandsName.includes(item.name)
            })

            obj = Object.assign({ [currentKey]: brandsObj }, obj)
          })

          this.brandGroups = this.sortObjectFromKey(obj)
          this.activeCategory = category
        }
      } else {
        this.resetFilter()
      }
      this.showFilterCategory = false;
    },
    resetFilter () {
      this.activeCategory = allCategoriesFilter
      this.activeAlphabet = 'all'
      this.brandGroups = this.arrayBrands
    },
    triggerTabAlphabet () {
      this.showFilterCategory = false
      this.showFilterAlphavet = !this.showFilterAlphavet
    },
    triggerTabCategory () {
      this.showFilterAlphavet = false
      this.showFilterCategory = !this.showFilterCategory
    }
  },
  metaInfo: metaBrands
})
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.brands {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;

  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-lists {
      flex: 0 0 100%;
      padding: 0 var(--spacer-10);
      order: 3;
      box-sizing: border-box;

      @include for-tablet {
        flex: 1 1;
        width: 73%;
        padding: 0 var(--spacer-18);
      }

      @include for-desktop {
        flex: 1 1;
        width: 73%;
        padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-sm) var(--spacer-64);
      }
    }
  }

  &-filter-category {
    flex: 0 0 100%;
    order: 1;

    @include for-desktop {
      flex: 0 0 13.75rem;
      padding: var(--spacer-sm);
      order: 2;
    }
  }
}

.navbar {
  position: relative;
  display: flex;

  ::v-deep .sf-heading__title {
    @include for-mobile {
      --heading-title-color: --black;
      --heading-title-font-size: var(--font-size-20);
      line-height: 1.2;
      text-align: left;
    }

    @include for-tablet {
      --heading-title-font-size: var(--font-size-32);
    }
  }

  &.section {
    padding: 0 var(--spacer-10);
    margin-bottom: var(--spacer-10);
    min-height: px2rem(24.5);

    @media (min-width: $tablet-min) {
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      margin-bottom: var(--spacer-sm);
      min-height: px2rem(38.5);
    }
  }

  &__main {
    .sf-heading {
      --heading-padding: 0 var(--spacer-20) 0 0;
    }
  }

  &__label {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin: 0 var(--spacer-2xs) 0 0;
    font-size: var(--font-size-13);
    white-space: nowrap;

    transition: opacity 200ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }

  &__counter {
    display: inline;
    font-family: var(--font-family-secondary);
  }

  &__title {
    display: inline;

    ::v-deep .sf-heading__title {
      padding: 0;
      display: inline;
      @include header-title;
    }
  }
}
</style>
